<template>
  <div class="p-5">
    <h1 class="text-2xl mb-4">Video Chat</h1>
    <div>
      <b-row>
        <b-col lg="8">
          <div id="my-video-chat-window"></div>
        </b-col>
        <b-col lg="4">
          <div class="text-end">
            <b-button @click="getAccessToken()">Join</b-button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import vedioApi from "@/Api/Modules/vediochat";
import Pusher from "pusher-js";
export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      accessToken: "",
    };
  },
  async created() {
    var pusher = new Pusher("09c8d5cbf40bbbb272f8", {
      cluster: "ap2",
    });

    var self = this;
    var channel = pusher.subscribe("data-updates");
    channel.bind("my-event", function (data) {
     
    });
  },
  methods: {
    async getAccessToken() {
      // Request a new token
      const _this = this;
      await vedioApi
        .getaccesstoken()
        .then(function (response) {
          _this.accessToken = response.data;
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          _this.connectToRoom();
        });
    },

    connectToRoom() {
      const { connect, createLocalVideoTrack } = require("twilio-video");

      connect(this.accessToken, { name: "cool room" }).then(
        (room) => {
          console.log(`Successfully joined a Room: ${room}`);

          const videoChatWindow = document.getElementById(
            "my-video-chat-window"
          );

          createLocalVideoTrack().then((track) => {
            videoChatWindow.appendChild(track.attach());
          });

          room.on("participantConnected", (participant) => {
            console.log(`Participant "${participant.identity}" connected`);

            participant.tracks.forEach((publication) => {
              if (publication.isSubscribed) {
                const track = publication.track;
                videoChatWindow.appendChild(track.attach());
              }
            });

            participant.on("trackSubscribed", (track) => {
              videoChatWindow.appendChild(track.attach());
            });
          });
        },
        (error) => {
          console.error(`Unable to connect to Room: ${error.message}`);
        }
      );
    },
  },
};
</script>
